import { useEffect } from "react";
import Template from "../app/Template";

export default function PrivacyPolicy() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Template>
      <div
        //@ts-ignore
        name="termly-embed"
        data-id="4e4a74b4-47c4-40c2-a099-a337d5f33f4c"
        data-type="iframe"
      ></div>
    </Template>
  );
}
