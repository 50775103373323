import { HelmetProvider } from "react-helmet-async";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";

// Redux
import { store } from "./store";
import { getAuth } from "./slices/auth";
import setAuthToken from "./utils/auth";
import NotFound from "./components/routing/NotFound";
import PrivateRoute from "./components/routing/PrivateRoute";
import Register from "./components/auth/Register";
import Verification from "./components/placeholders/Verification";
import VerificationConfirm from "./components/placeholders/VerificationConfirm";
import ForgotMyPassword from "./components/auth/ForgotMyPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Login from "./components/auth/Login";
import Profile from "./components/profile/Profile";
import Notifications from "./components/notifications/Notifications";
import Trending from "./components/landing/Trending";
import New from "./components/landing/New";
// import ForYou from "./components/landing/ForYou";
import PostBySlug from "./components/post/PostBySlug";
import EditProfile from "./components/profile/EditProfile";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GroupBySlug from "./components/interest/GroupBySlug";
import EditGroup from "./components/interest/EditGroup";
import Settings from "./components/settings/Settings";
import TopPosts from "./components/landing/TopPosts";
import Groups from "./components/interest/Groups";
import Profiles from "./components/profile/Profiles";
import ViewOnlyProfile from "./components/profile/ViewOnlyProfile";
import AdsProvider from "./components/contexts/AdProvider";
import PrivacyPolicy from "./components/documentation/PrivacyPolicy";
import TermsAndConditions from "./components/documentation/TermsAndConditions";
import Disclaimer from "./components/documentation/Disclaimer";
import AcceptableUsePolicy from "./components/documentation/AcceptableUsePolicy";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    store.dispatch(getAuth());
  }, []);

  return (
    <HelmetProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}>
        <Provider store={store}>
          <AdsProvider>
            <Router>
              <Routes>
                {/* Landing - Public */}
                <Route path="/top" element={<TopPosts />} />
                <Route path="/trending" element={<Trending />} />
                <Route path="/new" element={<New />} />
                {/* <Route path="/for-you" element={<ForYou />} /> */}

                {/* Post - Public */}
                <Route path="/post/:post_slug" element={<PostBySlug />} />

                {/* Group - Public */}
                <Route path="/group/:group_slug" element={<GroupBySlug />} />

                {/* Group - Public */}
                <Route path="/groups" element={<Groups />} />

                {/* Edit Group - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="Edit Group" />}>
                  <Route
                    path="/group/:group_slug/edit"
                    element={<EditGroup />}
                  />
                </Route>

                {/* Login - Public */}
                <Route path="/login" element={<Login />} />

                {/* Register - Public */}
                <Route path="/register" element={<Register />} />

                {/* Pending verification - Public */}
                <Route path="/verification" element={<Verification />} />

                {/* Verification confirmation - Public */}
                <Route
                  path="/verification/confirm/:profileId"
                  element={<VerificationConfirm />}
                />

                {/* Forgot my Password - Public */}
                <Route path="/forgot-my-password" element={<ForgotMyPassword />} />

                {/* Forgot my Password (Recovery) - Public */}
                <Route
                  path="/forgot-my-password/recovery"
                  element={<ResetPassword />}
                />

                {/* Profile - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="Profile" />}>
                  <Route
                    path="/profile"
                    element={<Profile />}
                  />
                </Route>

                {/* View Only Other Profile - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="View Profile" />}>
                  <Route
                    path="/profile/:profile_id"
                    element={<ViewOnlyProfile />}
                  />
                </Route>

                {/* Profiles - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="Profiles" />}>
                  <Route
                    path="/profiles"
                    element={<Profiles />}
                  />
                </Route>

                {/* Edit Profile - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="Edit Profile" />}>
                  <Route
                    path="/profile/edit"
                    element={<EditProfile />}
                  />
                </Route>

                {/* Notifications - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="Notifications" />}>
                  <Route
                    path="/notifications"
                    element={<Notifications />}
                  />
                </Route>

                {/* Settings - Private */}
                <Route element={<PrivateRoute allowedGroups={["Consumers"]} title="Settings" />}>
                  <Route
                    path="/settings"
                    element={<Settings />}
                  />
                </Route>

                {/* Documentation - Public */}
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />

                {/* Not Found */}
                <Route path="/404" element={<NotFound />} />
                <Route path="/" element={<Navigate replace to="/new" />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
              </Routes>
            </Router>
          </AdsProvider>
        </Provider>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
}

export default App;
