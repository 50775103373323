import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useEffect, useState } from "react";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { createGroup, getGroupsByQuery, getInterestGroupCategories, getTopGroups, GroupState } from "../../slices/interest";
import Card from "../layout/Card";
import Header from "../layout/Header";
import SearchInputWithIcon from "../forms/SearchInputWithIcon";
import Button from "../layout/Button";
import ActionButtons from "../layout/ActionButtons";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextInput from "../forms/TextInput";
import { addAlert } from "../../slices/alert";
import { resetImageUploads } from "../../slices/uploads";
import TextArea from "../forms/TextArea";
import Dropdown from "../forms/Dropdown";
import UploadImageDropzone from "../forms/UploadImageDropzone";

export default function Groups() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTopGroups({ pageNumber: 1 }));
    dispatch(getInterestGroupCategories())
  }, [dispatch]);

  // App level state from redux store
  const {
    user,
    loading,
    isAuthenticated
  } = useAppSelector((state) => state.auth);
  const {
    groups: {
      data: groupsData,
      loading: groupsLoading,
      waiting: groupsWaiting,
      pagination: groupsPagination
    }
  } = useAppSelector((state) => state.interest);
  const { images } = useAppSelector((state) => state.uploads);
  const { groups: { options: optionsGroups, loading: optionsGroupsLoading } } = useAppSelector((state) => state.interest);

  // Component level state
  const [page, setPage] = useState(1);
  const [textQuery, setTextQuery] = useState("");
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [createGroupContent, setCreateGroupContent] = useState<{
    name: string;
    description: string;
    category: string;
    subcategory: string[];
    profile_picture: string | null;
    cover_picture: string | null;
  }>({
    name: "",
    description: "",
    category: "",
    subcategory: [],
    profile_picture: null,
    cover_picture: null,
  });

  // Functions
  async function fetchGroupsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    setTextQuery(query);
    dispatch(getGroupsByQuery({ pageNumber, query, groupId: null, profileId: null }));
  }

  function triggerCreateGroup(e: any) {
    e.preventDefault();
    setShowCreateGroupModal(true);
  }

  async function confirmCreateGroup(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_create_group", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const {
      name,
      description,
      category,
      subcategory,
    } = createGroupContent;
    if (name === "") {
      dispatch(addAlert(generateTranslatedText("missing_name_group", language), "warning"));
      return
    }
    if (description === "") {
      dispatch(addAlert(generateTranslatedText("missing_description_group", language), "warning"));
      return
    }

    // Get processed images from redux store
    let imageData: any = { cover: null, profile: null };
    if ("group-profile-picture" in images.data) {
      imageData.profile = {
        id: "group-profile-picture",
        source: "processed",
        data: images.data["group-profile-picture"].data,
      };
    }
    if ("group-cover-picture" in images.data) {
      imageData.cover = {
        id: "group-cover-picture",
        source: "processed",
        data: images.data["group-cover-picture"].data,
      };
    }

    // Dispatch action
    setShowCreateGroupModal(false);
    const res: any = await dispatch(createGroup({
      administrators: [user.profile.id],
      name,
      description,
      category: category === "" ? null : category,
      subcategory,
      profile_picture: imageData.profile,
      cover_picture: imageData.cover,
      status: "A"
    }))
    if (res.payload.status === 201) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("group_created", language), "success"));
    } else {
      setShowCreateGroupModal(true);
      dispatch(addAlert(generateTranslatedText("group_created_error", language), "warning"));
    }
  }

  // Constant variables
  const language = "EN";
  const dropdownOptionsCategory = optionsGroups.category === undefined ? [] : optionsGroups.category.map((opt) => [opt.id, opt.name]);
  return (
    <Template>

      {/* Create group modal */}
      <Modal
        show={showCreateGroupModal}
        setShow={setShowCreateGroupModal}
        confirmText={generateTranslatedText("create", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmCreateGroup}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("create_a_group", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              {/* Name */}
              <TextInput
                id="group-name"
                type="text"
                placeholder={generateTranslatedText("name", language)}
                onChange={(e) => setCreateGroupContent({ ...createGroupContent, name: e.target.value })}
                required={true}
                showLabel={false}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={createGroupContent.name}
              />

              {/* Description */}
              <TextArea
                id="group-description"
                placeholder={generateTranslatedText("description", language)}
                onChange={(e) => setCreateGroupContent({ ...createGroupContent, description: e.target.value })}
                required={true}
                showLabel={false}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                rows={3}
                value={createGroupContent.description}
              />

              {/* Category */}
              {optionsGroupsLoading ? (
                <div className="flex justify-center">
                  <LoadingBubbles />
                </div>
              ) : (
                <Dropdown
                  id="group-category-dropdown"
                  label={generateTranslatedText("category", language)}
                  showLabel={false}
                  required={true}
                  onChange={(e) => setCreateGroupContent({ ...createGroupContent, category: e.target.value })}
                  value={createGroupContent.category}
                >
                  <option value="">Select a category</option>
                  {dropdownOptionsCategory.map((opt, idx) => (
                    <option key={idx} value={opt[0]}>{opt[1]}</option>
                  ))}
                </Dropdown>
              )}

              {/* Profile picture */}
              <UploadImageDropzone
                id="group-profile-picture"
                className="w-full"
                limit={1}
                label={generateTranslatedText("profile_picture", language)}
                savedImages={[]}
                bgColour="black-light"
                textColour="light/50"
                borderColour="light/50"
              />

              {/* Cover picture */}
              <UploadImageDropzone
                id="group-cover-picture"
                className="w-full"
                limit={1}
                label={generateTranslatedText("cover_picture", language)}
                savedImages={[]}
                bgColour="black-light"
                textColour="light/50"
                borderColour="light/50"
              />

            </div>
          </div>
        </div>
      </Modal>

      {/* Header */}
      <Header
        text={generateTranslatedText("groups", language)}
        actions={
          <ActionButtons
            primaryButton={
              <Button
                bgColour={`warning hover:bg-warning-dark`}
                onClick={triggerCreateGroup}
              >
                <span className="flex text-black">
                  <UserGroupIcon className="h-5 w-5 text-black mr-2" aria-hidden="true" />
                  Create a Group
                </span>
              </Button>
            }
          />
        }
      />

      <div className="grid grid-cols-1 gap-4 mt-6 px-4 sm:px-8 md:px-12 lg:px-20">

        {/* Loading spinner */}
        {groupsLoading || loading || user === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <div className="grid grid-cols-1 gap-10 pb-12">

            {/* Search bar */}
            <SearchInputWithIcon
              id="search-groups"
              name="search-groups"
              placeholder={generateTranslatedText("search_groups", language)}
              bgColour="black"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              onSubmit={fetchGroupsByPageAndQuery}
              onSubmitArgs={[page]}
              loading={loading}
            />

            {/* Content */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {groupsData.map((group: GroupState) => (
                <a href={`/group/${group.slug}`} key={group.slug} className="group">
                  <Card className="group-hover:border-light/20">
                    <div className="grid grid-cols-1 justify-center text-center gap-y-4 py-4">

                      {/* Profile picture */}
                      <div className="w-full flex justify-center">
                        <img
                          src={group.profile_picture === null ? "/img/graphics/placeholder_group_profile_picture.svg" : group.profile_picture}
                          alt={group.name}
                          className="w-32 h-32 object-cover rounded-full group-hover:ring-4 group-hover:ring-light/50"
                        />
                      </div>

                      {/* Name */}
                      <h1 className="text-xl font-bold text-white truncate">
                        {group.name}
                      </h1>

                      {/* Created at */}
                      <p className="text-sm text-secondary">
                        {`${generateTranslatedText("created_on", language)} ${group.created_date}`}
                      </p>

                      <div className="flex justify-center gap-4">

                        {/* Number of members */}
                        <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                          <UserGroupIcon className="w-5 h-5 text-warning" />
                          {`${group.num_members} ${group.num_members === 1 ? "Member" : "Members"}`}
                        </span>
                      </div>

                    </div>
                  </Card>
                </a>
              ))}
            </div>

            {/* Load more content */}
            {groupsPagination.has_next && (
              <div className="flex justify-center items-center my-10">
                {groupsWaiting ? (
                  <div className="flex justify-center items-center w-full my-20">
                    <LoadingBubbles />
                  </div>
                ) : (
                  <Button
                    bgColour="black hover:bg-white/10"
                    textColour="warning"
                    borderColour="warning"
                    className="items-center w-fit"
                    onClick={(e) => {
                      dispatch(getGroupsByQuery({ pageNumber: page + 1, query: textQuery, groupId: null, profileId: null }));
                      setPage(page + 1);
                    }}
                  >
                    <span className="flex items-center gap-x-1 text-warning">
                      {generateTranslatedText("load_more_content", language)}
                    </span>
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

      </div>
    </Template>
  );
}
