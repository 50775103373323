import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
export interface UploadsState {
  images: {
    data: {
      [key: string]: {
        id: string;
        name: string;
        data: string | null;
        loading: boolean;
        success: boolean;
        timestamp: any;
        errors: any[];
      };
    };
    removed: string[];
    loading: boolean;
    waiting: boolean;
    errors: string[];
  };
  options: {
    [key: string]: any;
  };
}

// Define the initial state using that type
const initialState: UploadsState = {
  images: {
    data: {},
    removed: [],
    loading: false,
    waiting: false,
    errors: [],
  },
  options: {},
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Validate and compress image
export const validateAndCompressImage = createAsyncThunk(
  "uploads/images/validate-compress/post",
  async (
    obj: {
      fileId: string;
      name: string;
      timestamp: number;
      b64Img: any;
      extension: string;
      blockId: string | null | undefined;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      // Build config
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = { img: obj.b64Img, extension: obj.extension };

      // Build endpoint URL
      const url =
        "https://tnroe3w7qg.execute-api.eu-west-2.amazonaws.com/prod/image-compression";

      // Send request
      const res = await axios.post(url, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  },
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const uploadsSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    removePhotoFromUploads: {
      prepare(
        fileId: string,
        blockId: string | null | undefined,
      ) {
        return { payload: { fileId, blockId } };
      },
      reducer(
        state,
        action: PayloadAction<{
          fileId: string;
          blockId: string | null | undefined;
        }>,
      ) {
        const { fileId } = action.payload;
        state.images.removed.push(fileId);
        if (Object.keys(state.images.data).includes(fileId)) {
          delete state.images.data[fileId];
        }
      },
    },
    resetImageUploads: {
      prepare() {
        return { payload: {} };
      },
      reducer(
        state,
        action: PayloadAction<{}>,
      ) {
        state.images.data = {};
      },
    },
    addbase64Image: {
      prepare(
        fileId: string,
        name: string,
        timestamp: number,
        b64String: string
      ) {
        return { payload: { fileId, name, timestamp, b64String } };
      },
      reducer(
        state,
        action: PayloadAction<{
          fileId: string;
          name: string;
          timestamp: number;
          b64String: string;
        }>,
      ) {
        const { fileId, name, timestamp, b64String } = action.payload;
        state.images.data[fileId] = {
          ...state.images.data[fileId],
          id: fileId,
          name: name,
          timestamp: timestamp,
          loading: false,
          data: b64String,
          success: true,
          errors: []
        }
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // Validate and compresss image
      .addCase(validateAndCompressImage.pending, (state, action) => {
        const { fileId, name, timestamp } = action.meta.arg;
        state.images.data[fileId] = {
          ...state.images.data[fileId],
          id: fileId,
          name: name,
          data: null,
          loading: true,
          success: false,
          timestamp,
        };

      })
      .addCase(validateAndCompressImage.fulfilled, (state, action) => {
        const { fileId } = action.meta.arg;
        const {
          success,
          compressed_img: compressedImg,
          message,
        } = action.payload.data;
        if (!success) {
          state.images.data[fileId].loading = false;
          state.images.data[fileId].data = null;
          state.images.data[fileId].success = false;
          state.images.data[fileId].errors = [message];
          return;
        }
        state.images.data[fileId].loading = false;
        state.images.data[fileId].success = true;
        state.images.data[fileId].data = compressedImg;
        state.images.data[fileId].errors = [];

      })
      .addCase(validateAndCompressImage.rejected, (state, action) => {
        const { fileId } = action.meta.arg;
        state.images.data[fileId].loading = false;
        state.images.data[fileId].data = null;
        state.images.data[fileId].success = false;
      })
  },
});

export const { removePhotoFromUploads, resetImageUploads, addbase64Image } = uploadsSlice.actions;
export default uploadsSlice.reducer;
