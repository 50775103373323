import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";


export interface PostState {
  id: string;
  created_date: string;
  created_date_iso: string;
  updated_date: string | null;
  updated_date_iso: string | null;
  created_by: string;
  created_by_pic: string | null;
  created_by_ref: string;
  created_by_id: string
  group: [string, string] | null;
  title: string;
  body: string;
  image: string | null;
  slug: string;
  num_comments: number;
  votes: {
    upvotes: number;
    downvotes: number;
    has_upvoted: boolean;
    has_downvoted: boolean;
  };
  status: [string, string];
}

export interface CommentState {
  id: string;
  created_date: string;
  created_date_iso: string;
  updated_date: string | null;
  updated_date_iso: string | null;
  created_by: string;
  created_by_pic: string | null;
  created_by_ref: string;
  created_by_id: string;
  post: [string, string];
  parent_comment: string | null;
  body: string;
  votes: {
    upvotes: number;
    downvotes: number;
    has_upvoted: boolean;
    has_downvoted: boolean;
  };
  children: CommentState[];
  status: [string, string];
}

// Define a type for the slice state
export interface ContentsState {
  posts: {
    data: PostState[];
    waiting: boolean;
    loading: boolean;
    errors: any[];
    options: { [key: string]: Array<string[]> };
    pagination: {
      has_previous: boolean;
      has_next: boolean;
      current_page: number;
      total_pages: number;
      total_objects: number;
    };
  },
  post: {
    data: PostState | null;
    waiting: boolean;
    loading: boolean;
    errors: any[];
  };
  comments: {
    data: CommentState[];
    waiting: boolean;
    replying: boolean;
    loading: boolean;
    errors: any[];
    pagination: {
      has_previous: boolean;
      has_next: boolean;
      current_page: number;
      total_pages: number;
      total_objects: number;
    };
  };
}

// Define the initial state using that type
const initialState: ContentsState = {
  posts: {
    data: [],
    waiting: false,
    loading: false,
    errors: [],
    options: {},
    pagination: {
      has_previous: false,
      has_next: false,
      current_page: 1,
      total_pages: 1,
      total_objects: 0
    }
  },
  post: {
    data: null,
    waiting: false,
    loading: false,
    errors: []
  },
  comments: {
    data: [],
    waiting: false,
    loading: false,
    replying: false,
    errors: [],
    pagination: {
      has_previous: false,
      has_next: false,
      current_page: 1,
      total_pages: 1,
      total_objects: 0
    }
  }
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get Top Voted posts
export const getTopVotedPosts = createAsyncThunk(
  "content/posts/top-voted/get",
  async (
    obj: {
      pageNumber: number;
      query: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = auth.user === null ? {} : auth.access === null ? {} : {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/content/post/top-voted${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Trending posts
export const getTrendingPosts = createAsyncThunk(
  "content/posts/trending/get",
  async (
    obj: {
      pageNumber: number;
      query: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = auth.user === null ? {} : auth.access === null ? {} : {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/content/post/trending${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get New posts
export const getNewPosts = createAsyncThunk(
  "content/posts/new/get",
  async (
    obj: {
      pageNumber: number;
      query: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = auth.user === null ? {} : auth.access === null ? {} : {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/content/post/new${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Post by Slug
export const getPostBySlug = createAsyncThunk(
  "content/post/slug/get",
  async (
    obj: {
      postSlug: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Build endpoint URL
      const url = `/api/content/post/slug/${obj.postSlug}`;

      // Send request
      const res = await axios.get(url);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Posts by Profile
export const getPostsByProfile = createAsyncThunk(
  "content/posts/profile/get",
  async (
    obj: {
      profileId: string | undefined;
      pageNumber: number;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };


      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();

      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/content/post/profile/${obj.profileId}${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

// Get Posts by query
export const getPostsByQueryAuthenticated = createAsyncThunk(
  "content/posts/profile/id/get",
  async (
    obj: {
      pageNumber: number;
      query: string | null;
      profileId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;
      if (obj.profileId !== null) params.profile = obj.profileId;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/content/post/search${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

// Vote for post
export const voteForPost = createAsyncThunk(
  "content/post/vote/put",
  async (
    obj: {
      postId: string;
      category: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.postId,
        category: obj.category,
      });

      const res = await axios.put(`/api/reaction/vote/post`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Vote for comment
export const voteForComment = createAsyncThunk(
  "content/comment/vote/put",
  async (
    obj: {
      commentId: string;
      category: string;
      parentId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.commentId,
        category: obj.category,
      });

      const res = await axios.put(`/api/reaction/vote/comment`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Post Comments by Post Slug
export const getPostCommentsBySlug = createAsyncThunk(
  "content/post/comments/get",
  async (
    obj: {
      postSlug: string;
      pageNumber: number;
      highlightedComment: string | null
    },
    { getState, rejectWithValue }
  ) => {
    try {

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = auth.access !== null ? {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      } : {};

      const params: { [key: string]: string } = {};
      params.slug = obj.postSlug;
      params.page = obj.pageNumber.toString();
      if (obj.highlightedComment !== null && obj.highlightedComment !== "") params.comment = obj.highlightedComment;
      const searchParams = new URLSearchParams(params).toString();

      // Build endpoint URL
      const url = `/api/content/comment/post${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create a reply to a Comment
export const createReplyToPost = createAsyncThunk(
  "content/comment/reply/post",
  async (
    obj: {
      postId: string;
      commentId: string | null;
      comment: string;
      parentId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        post: obj.postId,
        parent_comment: obj.parentId,
        body: obj.comment,
        status: "A"
      });

      const res = await axios.post(`/api/content/comment`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create post
export const createPost = createAsyncThunk(
  "content/post/create/post",
  async (
    obj: {
      title: string;
      body: string;
      image: {
        id: string;
        source: string;
        data: string;
      } | null;
      group: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        title: obj.title,
        body: obj.body,
        image: obj.image,
        group: obj.group,
        status: "A"
      });

      const res = await axios.post(`/api/content/post`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Edit post
export const editPost = createAsyncThunk(
  "content/post/edit/post",
  async (
    obj: {
      postId: string;
      title: string;
      body: string;
      image: {
        id: string;
        source: string;
        data: string;
      } | null;
      group: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.postId,
        title: obj.title,
        body: obj.body,
        image: obj.image,
        group: obj.group,
        status: "A"
      });

      const res = await axios.put(`/api/content/post`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete post
export const deletePost = createAsyncThunk(
  "content/post/delete/post",
  async (
    obj: {
      postId: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const headers = {
        Authorization: `Bearer ${auth.access}`,
        "Content-Type": "application/json",
      };

      const body = JSON.stringify({
        id: obj.postId,
      });

      const res = await axios.delete(`/api/content/post`, { data: body, headers });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Edit comment
export const editComment = createAsyncThunk(
  "content/comment/edit/post",
  async (
    obj: {
      postId: string;
      commentId: string;
      comment: string;
      parentId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.commentId,
        post: obj.postId,
        parent_comment: obj.parentId,
        body: obj.comment,
        status: "A"
      });

      const res = await axios.put(`/api/content/comment`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete comment
export const deleteComment = createAsyncThunk(
  "content/comment/delete/post",
  async (
    obj: {
      commentId: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const headers = {
        Authorization: `Bearer ${auth.access}`,
        "Content-Type": "application/json",
      };

      const body = JSON.stringify({
        id: obj.commentId,
      });

      const res = await axios.delete(`/api/content/comment`, { data: body, headers });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Posts by Group Slug
export const getPostsByGroupSlug = createAsyncThunk(
  "content/post/group/slug/get",
  async (
    obj: {
      groupSlug: string | undefined;
      pageNumber: number;
      query: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;

      const searchParams = new URLSearchParams(params).toString();

      // Build endpoint URL
      const url = `/api/content/post/group/slug/${obj.groupSlug}${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    clearComments: {
      prepare(commentIds: string[]) {
        return { payload: { commentIds } };
      },
      reducer(state, action: PayloadAction<{ commentIds: string[] }>) {

        // Remove all comments from state that are in the commentIds array
        state.comments.data = state.comments.data.filter((comment) => !action.payload.commentIds.includes(comment.id));

        // Reset pagination
        state.comments.pagination = {
          has_previous: false,
          has_next: false,
          current_page: 1,
          total_pages: 1,
          total_objects: 0
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // Get Top Voted posts
      .addCase(getTopVotedPosts.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = true;
        } else {
          state.posts.waiting = true;
        }
      })
      .addCase(getTopVotedPosts.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.data = pageNumber === 1 ? action.payload.data.data : [...state.posts.data, ...action.payload.data.data];
        state.posts.pagination = action.payload.data.pagination;
      })
      .addCase(getTopVotedPosts.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.errors.push(action.payload as string);
      })

      // Get Trending posts
      .addCase(getTrendingPosts.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = true;
        } else {
          state.posts.waiting = true;
        }
      })
      .addCase(getTrendingPosts.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.data = pageNumber === 1 ? action.payload.data.data : [...state.posts.data, ...action.payload.data.data];
        state.posts.pagination = action.payload.data.pagination;
      })
      .addCase(getTrendingPosts.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.errors.push(action.payload as string);
      })

      // Get New posts
      .addCase(getNewPosts.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = true;
        } else {
          state.posts.waiting = true;
        }
      })
      .addCase(getNewPosts.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.data = pageNumber === 1 ? action.payload.data.data : [...state.posts.data, ...action.payload.data.data];
        state.posts.pagination = action.payload.data.pagination;
      })
      .addCase(getNewPosts.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.errors.push(action.payload as string);
      })

      // Get post by slug
      .addCase(getPostBySlug.pending, (state, action) => {
        state.post.loading = true;
      })
      .addCase(getPostBySlug.fulfilled, (state, action) => {
        state.post.loading = false;
        state.post.data = action.payload.data;
      })
      .addCase(getPostBySlug.rejected, (state, action) => {
        state.post.errors.push(action.payload as string);
        state.post.loading = false;
      })

      // Get Posts by query
      .addCase(getPostsByQueryAuthenticated.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = true;
        } else {
          state.posts.waiting = true;
        }
      })
      .addCase(getPostsByQueryAuthenticated.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.data = pageNumber === 1 ? action.payload.data.data : [...state.posts.data, ...action.payload.data.data];
        state.posts.pagination = action.payload.data.pagination;
      })
      .addCase(getPostsByQueryAuthenticated.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.errors.push(action.payload as string);
      })

      // Vote for post
      .addCase(voteForPost.pending, (state, action) => {
        // Do nothing
      })
      .addCase(voteForPost.fulfilled, (state, action) => {
        // Find post in array and update at index
        const postIndex = state.posts.data.findIndex((post) => post.id === action.payload.data.id);
        state.posts.data[postIndex] = action.payload.data;
      })
      .addCase(voteForPost.rejected, (state, action) => {
        state.posts.errors.push(action.payload as string);
      })

      // Vote for comment
      .addCase(voteForComment.pending, (state, action) => {
        // Do nothing
      })
      .addCase(voteForComment.fulfilled, (state, action) => {
        // Get parent comment ID
        const parentId = action.meta.arg.parentId;

        // If parent comment ID is null, find comment in array and update the votes
        if (parentId === null) {
          const commentIndex = state.comments.data.findIndex((comment) => comment.id === action.payload.data.id);
          state.comments.data[commentIndex].votes = action.payload.data.votes;
          return;
        }

        // If parent comment ID is not null, find parent comment in array
        const parentCommentIndex = state.comments.data.findIndex((comment) => comment.id === parentId);
        if (parentCommentIndex === -1) return;

        // Find child comment in parent comment's children array and update votes at index
        const childCommentIndex = state.comments.data[parentCommentIndex].children.findIndex((comment) => comment.id === action.payload.data.id);
        state.comments.data[parentCommentIndex].children[childCommentIndex].votes = action.payload.data.votes;
      })
      .addCase(voteForComment.rejected, (state, action) => {
        state.comments.errors.push(action.payload as string);
      })

      // Get Post Comments by Post Slug
      .addCase(getPostCommentsBySlug.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.comments.loading = true;
        } else {
          state.comments.waiting = true;
        }
      })
      .addCase(getPostCommentsBySlug.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.comments.loading = false;
        } else {
          state.comments.waiting = false;
        }
        state.comments.data = pageNumber === 1 ? action.payload.data.data : [...state.comments.data, ...action.payload.data.data];
        state.comments.pagination = action.payload.data.pagination;
      })
      .addCase(getPostCommentsBySlug.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.comments.loading = false;
        } else {
          state.comments.waiting = false;
        }
        state.comments.errors.push(action.payload as string);
      })

      // Create a reply to a Post
      .addCase(createReplyToPost.pending, (state, action) => {
        state.comments.replying = true;
      })
      .addCase(createReplyToPost.fulfilled, (state, action) => {
        // Get parent comment ID from action meta
        const parentCommentId = action.meta.arg.parentId;
        const postId = action.meta.arg.postId;

        // Get post index in array of posts
        const postIndex = state.posts.data.findIndex((post) => post.id === postId);

        // If parent ID doesn't exist, add comment to the start of the array
        if (parentCommentId === null) {
          state.comments.data.unshift(action.payload.data);
          state.comments.replying = false;

          // Update post's number of comments
          state.posts.data[postIndex].num_comments += 1;
          return;
        }

        // If parent ID exists, find parent comment in array and add reply to children array in first position
        const parentCommentIndex = state.comments.data.findIndex((comment) => comment.id === parentCommentId);
        state.comments.data[parentCommentIndex].children.unshift(action.payload.data);
        state.comments.replying = false;

        // Update post's number of comments
        state.posts.data[postIndex].num_comments += 1;
      })
      .addCase(createReplyToPost.rejected, (state, action) => {
        state.comments.errors.push(action.payload as string);
        state.comments.replying = false;
      })

      // Edit comment
      .addCase(editComment.pending, (state, action) => {
        state.comments.replying = true;
      })
      .addCase(editComment.fulfilled, (state, action) => {
        // Get parent comment ID from action meta
        const parentCommentId = action.meta.arg.parentId;

        // If parent ID doesn't exist, find comment in array and update at index
        if (parentCommentId === null) {
          const commentIndex = state.comments.data.findIndex((comment) => comment.id === action.payload.data.id);
          state.comments.data[commentIndex] = action.payload.data;
          state.comments.replying = false;
          return;
        }

        // If parent ID exists, find parent comment in array and update reply in children array at index
        const parentCommentIndex = state.comments.data.findIndex((comment) => comment.id === parentCommentId);
        const childCommentIndex = state.comments.data[parentCommentIndex].children.findIndex((comment) => comment.id === action.payload.data.id);
        state.comments.data[parentCommentIndex].children[childCommentIndex] = action.payload.data;
        state.comments.replying = false;
      })
      .addCase(editComment.rejected, (state, action) => {
        state.comments.errors.push(action.payload as string);
        state.comments.replying = false;
      })

      // Delete comment
      .addCase(deleteComment.pending, (state, action) => {
        state.comments.loading = true;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.comments.loading = false;

        // Get comment ID and post ID from action meta
        const commentId = action.payload.data.id;
        const postId = action.payload.data.post_id;
        const parentComment = action.payload.data.parent_comment;

        // Find comment object from array
        const commentIndex = state.comments.data.findIndex((comment) => comment.id === commentId);
        const commentToDelete = state.comments.data[commentIndex];
        const postIndex = state.posts.data.findIndex((post) => post.id === postId);

        // If comment index not found, find parent comment
        if (commentIndex === -1 || commentToDelete === undefined) {

          // Find parent comment in array
          const parentCommentIndex = state.comments.data.findIndex((comment) => comment.id === parentComment);

          // Find child comment in parent comment's children array and remove it
          state.comments.data[parentCommentIndex].children = state.comments.data[parentCommentIndex].children.filter((comment) => comment.id !== action.payload.data.id);
          state.posts.data[postIndex].num_comments -= 1;
          return;
        }

        state.comments.data = state.comments.data.filter((comment) => comment.id !== action.payload.data.id);

        // Get number of children of post to be deleted
        const numChildren = commentToDelete.children.length;

        // Update number of comments in post
        state.posts.data[postIndex].num_comments -= numChildren + 1;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.comments.errors.push(action.payload as string);
      })

      // Create post
      .addCase(createPost.pending, (state, action) => {
        state.posts.loading = true;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.posts.loading = false;
        state.posts.data.unshift(action.payload.data);
      })
      .addCase(createPost.rejected, (state, action) => {
        state.posts.errors.push(action.payload as string);
      })

      // Edit post
      .addCase(editPost.pending, (state, action) => {
        state.posts.loading = true;
        state.post.loading = true;
      })
      .addCase(editPost.fulfilled, (state, action) => {
        state.posts.loading = false;
        const postIndex = state.posts.data.findIndex((post) => post.id === action.payload.data.id);
        state.posts.data[postIndex] = action.payload.data;
        state.post.data = action.payload.data;
        state.post.loading = false;
      })
      .addCase(editPost.rejected, (state, action) => {
        state.posts.errors.push(action.payload as string);
        state.posts.loading = false;
        state.post.loading = false;
        state.post.errors.push(action.payload as string);
      })

      // Delete post
      .addCase(deletePost.pending, (state, action) => {
        state.posts.loading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.posts.loading = false;
        state.posts.data = state.posts.data.filter((post) => post.id !== action.payload.data.id);
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.posts.errors.push(action.payload as string);
      })

      // Get Posts by Profile
      .addCase(getPostsByProfile.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = true;
        } else {
          state.posts.waiting = true;
        }
      })
      .addCase(getPostsByProfile.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.data = pageNumber === 1 ? action.payload.data.data : [...state.posts.data, ...action.payload.data.data];
        state.posts.pagination = action.payload.data.pagination;
      })
      .addCase(getPostsByProfile.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.errors.push(action.payload as string);
      })

      // Get Posts by Group Slug
      .addCase(getPostsByGroupSlug.pending, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = true;
        } else {
          state.posts.waiting = true;
        }
      })
      .addCase(getPostsByGroupSlug.fulfilled, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.data = pageNumber === 1 ? action.payload.data.data : [...state.posts.data, ...action.payload.data.data];
        state.posts.pagination = action.payload.data.pagination;
      })
      .addCase(getPostsByGroupSlug.rejected, (state, action) => {
        const pageNumber = action.meta.arg.pageNumber;
        if (pageNumber === 1) {
          state.posts.loading = false;
        } else {
          state.posts.waiting = false;
        }
        state.posts.errors.push(action.payload as string);
      });
  },
});

export const { clearComments } = contentSlice.actions;
export default contentSlice.reducer;
