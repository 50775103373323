import Template from "../app/Template";
import CreateOrSearchHeader from "./CreateOrSearchHeader";
import TopGroups from "./TopGroups";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTopVotedPosts, PostState } from "../../slices/content";
import { useSearchParams } from "react-router-dom";
import LoadingBubbles from "../layout/LoadingBubbles";
import LandingFilters from "./LandingFilters";
import FeedFilmItem from "../layout/FeedFilmItem";
import Button from "../layout/Button";
import { generateTranslatedText } from "../../utils/boilerplate";
import MobileBottomToolbar from "./MobileBottomToolbar";
import { useAds } from "../contexts/AdProvider";
import BannerAds from "../advertisement/BannerAds";

export default function TopPosts() {
  const dispatch = useAppDispatch();

  // Get query params
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("query");

  // Get app level contexts
  const { banner: { data: bannerAdsData, loading: bannerAdsLoading } } = useAds();

  // Get app level state from redux store
  const { loading: authLoading } = useAppSelector((state) => state.auth);
  const { posts: { data, loading, waiting, pagination } } = useAppSelector((state) => state.content);

  // On page load
  useEffect(() => {
    if (!authLoading) {
      dispatch(getTopVotedPosts({ pageNumber: 1, query: querySearch }));
    }
  }, [dispatch, querySearch, authLoading]);

  // Get component level state
  const [page, setPage] = useState(1);
  const [_, setShowSearchModal] = useState(false);

  // Functions
  async function fetchTopVotedPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getTopVotedPosts({ pageNumber, query }));
    setShowSearchModal(false);
  }

  const language = "EN";
  return (
    <Template>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:mt-16">

        {/* Posts content */}
        <div className="col-span-2">

          {/* Banner Ad */}
          {bannerAdsLoading ? (
            <div className="flex justify-center items-center w-full my-16">
              <LoadingBubbles />
            </div>
          ) : bannerAdsData.length === 0 ? null : (
            <BannerAds ads={bannerAdsData} />
          )}

          {/* Create post or search */}
          <CreateOrSearchHeader
            searchHandler={fetchTopVotedPostsByPageAndQuery}
            searchHandlerArgs={[page]}
            loading={loading}
          />

          {/* Filter buttons */}
          <LandingFilters page="/top" />

          {/* Feed */}
          {loading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : data.map((post: PostState) => (
            <FeedFilmItem post={post} key={post.id} hideLongText={true} />
          ))}

          {/* Load more content */}
          {pagination.has_next && (
            <div className="flex justify-center items-center my-10">
              {waiting ? (
                <div className="flex justify-center items-center w-full my-20">
                  <LoadingBubbles />
                </div>
              ) : (
                <Button
                  bgColour="black hover:bg-white/10"
                  textColour="warning"
                  borderColour="warning"
                  className="items-center w-fit"
                  onClick={() => {
                    dispatch(getTopVotedPosts({ pageNumber: page + 1, query: querySearch }))
                    setPage(page + 1);
                  }}
                >
                  <span className="flex items-center gap-x-1 text-warning">
                    {generateTranslatedText("load_more_content", language)}
                  </span>
                </Button>
              )}
            </div>
          )}

        </div>

        {/* Groups content */}
        <div className="col-span-1">
          <TopGroups />
        </div>

      </div>

      {/* Mobile toolbar */}
      <MobileBottomToolbar pageNumber={page} pageName="top" loading={loading} />

    </Template>
  );
}
