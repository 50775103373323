import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useEffect, useState } from "react";
import {
  getAuth,
  updateProfile,
} from "../../slices/auth";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import { SparklesIcon } from "@heroicons/react/24/solid";
import Button from "../layout/Button";
import Card from "../layout/Card";
import TextInput from "../forms/TextInput";
import TextArea from "../forms/TextArea";
import UploadImageDropzone from "../forms/UploadImageDropzone";
import { addAlert } from "../../slices/alert";
import { resetImageUploads } from "../../slices/uploads";
import Header from "../layout/Header";
import Breadcrumbs from "../layout/Breadcrumbs";

interface UpdateProfileData {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  email: string;
  username: string;
  bio: string;
  cover_picture: string;
  profile_picture: string;
}

export default function EditProfile() {
  const dispatch = useAppDispatch();

  // Constant variables
  const language = "EN";

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  // App level state from redux store
  const {
    user,
    loading,
    isAuthenticated
  } = useAppSelector((state) => state.auth);
  const { images } = useAppSelector((state) => state.uploads);

  // Component level state
  const [updateFormData, setUpdateFormData] = useState<UpdateProfileData>({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    email: "",
    username: "",
    bio: "",
    cover_picture: "",
    profile_picture: "",
  });

  // Functions
  async function saveProfile(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_update_profile", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const {
      first_name,
      last_name,
      username,
      bio,
    } = updateFormData;

    // Get processed images from redux store
    let imageData: any = { cover: null, profile: null };
    if ("profile-picture" in images.data) {
      imageData.profile = {
        id: "profile-picture",
        source: "processed",
        data: images.data["profile-picture"].data,
      };
    }
    if ("cover-picture" in images.data) {
      imageData.cover = {
        id: "cover-picture",
        source: "processed",
        data: images.data["cover-picture"].data,
      };
    }

    // Dispatch action
    const res: any = await dispatch(updateProfile({
      firstName: first_name === "" ? user.first_name : first_name,
      lastName: last_name === "" ? user.last_name : last_name,
      email: user.email,
      username: username === "" ? user.username : username,
      currentPassword: "",
      updatePassword: "",
      confirmPassword: "",
      dateOfBirth: user.profile.date_of_birth,
      profilePicture: imageData.profile,
      coverPicture: imageData.cover,
      bio: bio === "" ? user.profile.bio || "" : bio,
    }))
    if (res.payload.status === 200) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("profile_updated", language), "success"));
    } else {
      console.log(res);
      const error = res.payload.response.data;
      if (error.username !== undefined) {
        dispatch(addAlert(generateTranslatedText("update_username_taken_error", language), "warning"));
      } else {
        dispatch(addAlert(generateTranslatedText("profile_updated_error", language), "warning"));
      }
    }
  }

  return (
    <Template>

      {/* Header */}
      <Header
        text={generateTranslatedText("edit_profile", language)}
        breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: `/profile`, text: "← Back to Profile" }]} />}
      />

      <div className="grid grid-cols-1 gap-4 my-8">

        {/* Loading spinner */}
        {loading || user === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <Card className="w-4/5 md:w-2/3 lg:w-1/2 mx-auto grid grid-cols-1 gap-y-6">

            {/* Profile picture */}
            <UploadImageDropzone
              id="profile-picture"
              label={generateTranslatedText("profile_picture", language)}
              className="w-full"
              limit={1}
              savedImages={user.profile.profile_picture === null ? [] : [{ id: "saved-profile-picture", data: user.profile.profile_picture }]}
              bgColour="black-light"
              textColour="light/50"
              borderColour="light/50"
              restrictUploadSize={false}
              compress={true}
            />

            {/* Cover photo */}
            <UploadImageDropzone
              id="cover-picture"
              label={generateTranslatedText("cover_picture", language)}
              className="w-full"
              limit={1}
              savedImages={user.profile.cover_picture === null ? [] : [{ id: "saved-cover-picture", data: user.profile.cover_picture }]}
              bgColour="black-light"
              textColour="light/50"
              borderColour="light/50"
              restrictUploadSize={true}
              compress={false}
            />

            {/* First name */}
            <TextInput
              id="profile-first-name"
              type="text"
              label={generateTranslatedText("first_name", language)}
              placeholder={user.first_name === "" ? generateTranslatedText("first_name_placeholder", language) : user.first_name}
              onChange={(e) => setUpdateFormData({ ...updateFormData, first_name: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={updateFormData.first_name}
            />

            {/* Last name */}
            <TextInput
              id="profile-last-name"
              type="text"
              label={generateTranslatedText("last_name", language)}
              placeholder={user.last_name === "" ? generateTranslatedText("last_name_placeholder", language) : user.last_name}
              onChange={(e) => setUpdateFormData({ ...updateFormData, last_name: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={updateFormData.last_name}
            />

            {/* Email */}
            <TextInput
              id="profile-email"
              type="email"
              label={generateTranslatedText("email", language)}
              placeholder={generateTranslatedText("email_placeholder", language)}
              onChange={(e) => setUpdateFormData({ ...updateFormData, email: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={user.email}
              disabled={true}
            />

            {/* Username */}
            <TextInput
              id="profile-username"
              type="text"
              label={generateTranslatedText("username", language)}
              placeholder={user.username === "" ? generateTranslatedText("username_placeholder", language) : user.username}
              onChange={(e) => setUpdateFormData({ ...updateFormData, username: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={updateFormData.username}
            />

            {/* Bio */}
            <TextArea
              id="post-title"
              label={generateTranslatedText("bio", language)}
              placeholder={user.profile.bio === "" || user.profile.bio === null ? generateTranslatedText("bio_placeholder", language) : user.profile.bio}
              onChange={(e) => setUpdateFormData({ ...updateFormData, bio: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              rows={5}
              value={updateFormData.bio}
            />

            {/* Save button */}
            <Button
              bgColour="warning hover:bg-warning-dark"
              textColour="black"
              borderColour="black"
              onClick={saveProfile}
            >
              <span className="flex items-center justify-center gap-x-2 text-black">
                <SparklesIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                Save
              </span>
            </Button>

          </Card>
        )}

      </div>
    </Template>
  );
}
