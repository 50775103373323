import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import {
  ArrowLeftStartOnRectangleIcon,
  BellIcon,
  Cog8ToothIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import ClickableLink from "../layout/ClickableLink";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { generateTranslatedText } from "../../utils/boilerplate";
import { logout } from "../../slices/auth";
import { addAlert } from "../../slices/alert";
import Button from "../layout/Button";
import Modal from "../layout/Modal";

export default function Navbar() {
  const dispatch = useAppDispatch();
  const { user, isAuthenticated, loading } = useAppSelector((state) => state.auth);
  // const language = !user ? "EN" : user.profile.language[0];
  const language = "EN";
  const [menuOpen, setMenuOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  // Functions
  async function triggerLogout(e: any) {
    e.preventDefault();
    const res: any = await dispatch(logout());
    if (res.payload.status === 205) {
      setMenuOpen(false);
      dispatch(
        addAlert(generateTranslatedText("logout_success", language), "success", 5000),
      );
    } else {
      dispatch(addAlert(generateTranslatedText("logout_error", language), "danger"));
    }
  }

  const navigationLinks = [
    { name: "Home", href: "/", icon: HomeIcon, onClick: null, showAlert: 0, authenticated: false },
    { name: "Notifications", href: "/notifications", icon: BellIcon, onClick: null, showAlert: user === null ? 0 : user.profile.num_notifications, authenticated: false },
    { name: "Profile", href: "/profile", icon: UserIcon, onClick: null, showAlert: 0, authenticated: true },
    { name: "Groups", href: "/groups", icon: UserGroupIcon, onClick: null, showAlert: 0, authenticated: false },
    { name: "Find People", href: "/profiles", icon: UsersIcon, onClick: null, showAlert: 0, authenticated: false },
    { name: "Settings", href: "/settings", icon: Cog8ToothIcon, onClick: null, showAlert: 0, authenticated: true },
    { name: "Logout", href: null, icon: ArrowLeftStartOnRectangleIcon, onClick: triggerLogout, showAlert: 0, authenticated: true },
  ]

  const filteredNavigationLinks = navigationLinks.filter((link) => {
    if (link.authenticated) {
      return isAuthenticated;
    }
    return true;
  })

  return (
    <header className="bg-black-light border-none sticky top-0 z-40 text-white">

      {/* Help modal */}
      <Modal
        show={showHelpModal}
        setShow={setShowHelpModal}
        confirmText=""
        cancelText=""
        confirmHandler={() => { }}
        showActionButtons={false}
        showTriggerButton={false}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("help", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-base text-white">If you have any questions or issues with the site please contact <a href="mailto:help@filmwaffle.com" target="_blank" rel="noreferrer" className="text-warning hover:underline">help@filmwaffle.com</a></p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Desktop */}
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
        aria-label="Global"
      >
        {/* Logo */}
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 shrink-0">
            <span className="sr-only">FilmWaffle</span>
            <img
              className="h-auto w-44 hidden sm:block"
              src="/img/logo/logo_transparent.svg"
              alt="FilmWaffle logo"
            />
            <img
              className="h-auto w-12 block sm:hidden"
              src="/img/logo/logo_icon.svg"
              alt="FilmWaffle logo"
            />
          </a>
        </div>

        {/* Help button */}
        <button className="flex ml-auto" onClick={() => setShowHelpModal(true)}>
          <QuestionMarkCircleIcon className="h-6 w-6 text-white hover:text-warning" aria-hidden="true" />
        </button>

        {/* Auth buttons */}
        {loading ? null : !isAuthenticated || !user ? (
          <div className="flex items-center justify-end">
            <span className="flex space-x-4">
              <ClickableLink
                href="/login"
                bgColour="warning hover:bg-warning-dark"
                textColour="text-black"
                shadow="none"
              >
                {generateTranslatedText("login", language)}
              </ClickableLink>
              <ClickableLink
                bgColour="black-light hover:bg-white/10"
                textColour="text-warning"
                borderColour="warning"
                href="/register"
              >
                {generateTranslatedText("sign_up", language)}
              </ClickableLink>
            </span>
          </div>
        ) : (
          <div className="flex items-center justify-end">
            <button
              type="button"
              onClick={() => setMenuOpen(true)}
            >
              <img className="h-10 w-10 object-cover rounded-full border-none ring ring-2 ring-light/50 hover:ring-warning ring-offset-2 ring-offset-black-light" src={user.profile.profile_picture || "/img/graphics/placeholder_profile_picture.svg"} alt={user.username} />
            </button>
          </div>
        )}
      </nav>

      {/* Menu */}
      <Dialog
        open={menuOpen}
        onClose={setMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black border-l-2 border-warning px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-secondary/30">
          <div className="flex items-center justify-between gap-x-6">

            {/* Logo */}
            <a href="/" className="-m-1.5 p-1.5 bg-pink rounded-md box-shadow-black-5 border-2 border-black">
              <span className="sr-only">FilmWaffle</span>
              <img
                className="h-12 w-auto"
                src="/img/logo/logo_icon.svg"
                alt=""
              />
            </a>

            {/* Close button */}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Navigation links */}
          <div className="grid grid-cols-1 mt-12 divide-y divide-light/20">

            {/* Links */}
            {filteredNavigationLinks.map((link) => (
              <a className="" key={link.name} href={link.href || "#!"} onClick={(e) => link.onClick === null ? null : link.onClick(e)}>
                <div className="flex items-center justify-between py-4 px-4 hover:bg-black-light rounded-md">
                  <div className="flex items-center">
                    <link.icon className={`h-6 w-6 text-warning ${link.showAlert && "animate-bounce"}`} aria-hidden="true" />
                    <span className="ml-3 text-base font-medium text-white">{link.name}</span>
                    {link.showAlert > 0 && (
                      <span className="ml-2 bg-warning text-black text-xs font-semibold rounded-full px-2 py-1">{link.showAlert}</span>
                    )}
                  </div>
                </div>
              </a>
            ))}

          </div>

        </DialogPanel>
      </Dialog>
    </header>
  );
}
