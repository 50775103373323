import { useEffect, useState } from "react";
import { AdState } from "../../slices/advertisement";

export default function BannerAds({ ads = [] }: { ads: AdState[] }) {

  // Component level state
  const [index, setIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setIndex((prev) => (prev === ads.length - 1 ? 0 : prev + 1));
        setIsFading(false); // Trigger fade-in
      }, 200); // Match the transition duration
    }, 25000);
    return () => clearInterval(interval);
  }, [ads]);

  if (ads.length === 0) return null;
  return (
    <div className="relative overflow-hidden w-full h-32 sm:h-48 rounded-md mb-4">
      {ads.map((ad, i) => (
        <a key={i} href={ad.url === null ? "#!" : ad.url} target="_blank" rel="noreferrer">

          {/* XS */}
          {ad.image_xs !== null && (
            <img
              src={ad.image_xs}
              alt={ad.title}
              className={`absolute block xs:hidden w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${i === index ? (isFading ? "opacity-0" : "opacity-100") : "opacity-0"}`}
            />
          )}

          {/* SM */}
          {ad.image_sm !== null && (
            <img
              src={ad.image_sm}
              alt={ad.title}
              className={`absolute hidden xs:block w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${i === index ? (isFading ? "opacity-0" : "opacity-100") : "opacity-0"}`}
            />
          )}

          {/* MD */}
          {ad.image_md !== null && (
            <img
              src={ad.image_md}
              alt={ad.title}
              className={`absolute hidden sm:block w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${i === index ? (isFading ? "opacity-0" : "opacity-100") : "opacity-0"}`}
            />
          )}

          {/* LG */}
          {ad.image_lg !== null && (
            <img
              src={ad.image_lg}
              alt={ad.title}
              className={`absolute hidden lg:block w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${i === index ? (isFading ? "opacity-0" : "opacity-100") : "opacity-0"}`}
            />
          )}

        </a>
      ))}
    </div>
  );
}
