import { useEffect } from "react";
import Template from "../app/Template";

export default function PrivacyPolicy() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Template>
      <div
        //@ts-ignore
        name="termly-embed"
        data-id="1596d7b7-bf1c-435d-aa51-7cd4ccdda9b2"
        data-type="iframe"
      ></div>
    </Template>
  );
}
