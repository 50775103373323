import { getSocialIcon } from "../../utils/icons";
import Button from "../layout/Button";

const navigation = {
  information: [
    { name: "About", href: "#" },
    { name: "Partners", href: "#" },
    { name: "FAQs", href: "#" },
  ],
  support: [
    { name: "Membership", href: "#" },
    { name: "Documentation", href: "#" },
    { name: "Guides", href: "#" },
  ],
  legal: [
    { name: "User agreement", href: "/terms-and-conditions" },
    { name: "Privacy policy", href: "/privacy-policy" },
    { name: "Disclaimer", href: "/disclaimer" },
    { name: "Acceptable use policy", href: "/acceptable-use-policy" },
  ],
  social: [
    // {
    //   name: "Facebook",
    //   href: "#",
    //   icon: (props: any) => getSocialIcon("facebook", props),
    // },
    // {
    //   name: "Instagram",
    //   href: "#",
    //   icon: (props: any) => getSocialIcon("instagram", props),
    // },
    // {
    //   name: "X",
    //   href: "#",
    //   icon: (props: any) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
    //     </svg>
    //   ),
    // },
    // {
    //   name: "GitHub",
    //   href: "#",
    //   icon: (props: any) => getSocialIcon("github", props),
    // },
    // {
    //   name: "YouTube",
    //   href: "#",
    //   icon: (props: any) => getSocialIcon("youtube", props),
    // },
    {
      name: "TikTok",
      href: "https://www.tiktok.com/@filmwaffle",
      icon: (props: any) => getSocialIcon("tiktok", props),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/filmwaffle/",
      icon: (props: any) => getSocialIcon("linkedin", props),
    },
  ],
};

export default function Footer() {
  return (
    <footer
      className="bg-black-light border-t-2 border-black"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-10 sm:pt-24 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-secondary hover:text-warning"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Information
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.information.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-secondary hover:text-warning"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
              {/* <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Support
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-secondary hover:text-warning"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {/* <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-secondary hover:text-warning"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
          </div>
          <div className="mt-10 xl:mt-0">


          </div>
        </div>
        <div className="pt-8 mb-20 md:my-12 md:flex md:items-center md:justify-between lg:mt-24">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-white"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-white md:order-1 md:mt-0">
            &copy; {(new Date).getFullYear()} @ FilmWaffle Ltd, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
