import Button from "../layout/Button";
import Card from "../layout/Card";
import {
  clearComments,
  CommentState,
  createReplyToPost,
  deleteComment,
  deletePost,
  editComment,
  editPost,
  getPostCommentsBySlug,
  PostState,
  voteForComment,
  voteForPost
} from "../../slices/content";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  StarIcon as StarIconOutline,
  ChatBubbleOvalLeftIcon,
  ShareIcon,
  ChatBubbleLeftRightIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import FallingStar from "../icon/FallingStar";
import {
  StarIcon as StarIconSolid,
} from "@heroicons/react/24/solid";
import Modal from "./Modal";
import { useEffect, useState } from "react";
import { generateTranslatedText } from "../../utils/boilerplate";
import { Dialog } from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { FacebookShareButton, RedditShareButton } from "react-share";
import LoadingBubbles from "./LoadingBubbles";
import TextInputWithIcon from "../forms/TextInputWithIcon";
import TextInput from "../forms/TextInput";
import TextArea from "../forms/TextArea";
import UploadImageDropzone from "../forms/UploadImageDropzone";
import { resetImageUploads } from "../../slices/uploads";
import { getSocialIconWithoutProps } from "../../utils/icons";

export default function FeedFilmItem({
  post,
  showFirstPageOfComments = false,
  highlightedComment = null,
  hideLongText = false,
}: {
  post: PostState,
  showFirstPageOfComments?: boolean,
  highlightedComment?: string | null
  hideLongText?: boolean
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showFirstPageOfComments) {
      dispatch(getPostCommentsBySlug({
        postSlug: post.slug,
        pageNumber: 1,
        highlightedComment
      }))
    }
  }, [dispatch, post.slug, showFirstPageOfComments, highlightedComment])

  // Scroll to the highlighted element if possible
  useEffect(() => {
    const scrollToHighlightedComment = () => {
      const element = document.getElementById(`comment-${highlightedComment}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    setTimeout(scrollToHighlightedComment, 1000);
  }, [highlightedComment])

  // App level state from redux store
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);
  const { comments: { data, loading, waiting, pagination, replying } } = useAppSelector((state) => state.content);
  const { images } = useAppSelector((state) => state.uploads);

  // Component level state
  const [showShareModal, setShowShareModal] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);
  const [showDeletePostModal, setShowDeletePostModal] = useState(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState(false);
  const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
  const [editCommentContent, setEditCommentContent] = useState<{ id: string, post_id: string, body: string, parent_comment: string | null }>({
    id: "",
    post_id: "",
    body: "",
    parent_comment: null
  })
  const [deleteCommentContent, setDeleteCommentContent] = useState<{ id: string }>({
    id: "",
  })
  const [editPostContent, setEditPostContent] = useState<{ id: string, group: string | null, title: string, body: string, image: string | null, type: string }>({
    id: "",
    group: null,
    title: "",
    body: "",
    image: null,
    type: "post"
  })
  const [deletePostContent, setDeletePostContent] = useState<{ id: string }>({
    id: "",
  })
  const [copied, setCopied] = useState(false);
  const [chosenPostToShowComments, setChosenPostToShowComments] = useState(showFirstPageOfComments ? post.id : "");
  const [commentPage, setCommentPage] = useState(1);
  const [activeReplyToCommentInput, setActiveReplyToCommentInput] = useState<{ id: string, comment: string, parent_comment: string | null }>({
    id: "",
    comment: "",
    parent_comment: null
  })
  const [activeReplyToPostInput, setActiveReplyToPostInput] = useState<{ id: string, comment: string }>({
    id: "",
    comment: "",
  })
  const [showHiddenTextCounter, setShowHiddenTextCounter] = useState(1);

  // Functions
  function copyUrlToClipboard(postSlug: string) {
    navigator.clipboard.writeText(`${shareUrl}${postSlug}`);
    dispatch(addAlert(generateTranslatedText("copied_to_clipboard", "EN"), "success"));
    setCopied(true);
  }

  async function clickVoteButtonForPost(e: any, category: string, postId: string) {
    e.preventDefault();

    if (!isAuthenticated) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_vote", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    if (user === null) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_vote", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    // Send backend request
    const res: any = await dispatch(voteForPost({ postId, category }));
    if (![205, 200].includes(res.payload.status)) {
      dispatch(addAlert(generateTranslatedText("vote_error", language), "warning"));
    }
  }

  // Function
  async function clickVoteButtonForComment(e: any, category: string, commentId: string, parentId: string | null) {
    e.preventDefault();

    if (!isAuthenticated) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_vote", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    if (user === null) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_vote", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    // Send backend request
    const res: any = await dispatch(voteForComment({ commentId, category, parentId }));
    if (res.payload.status !== 200) {
      dispatch(addAlert(generateTranslatedText("vote_error", language), "warning"));
    }
  }

  async function replyToComment(postId: string, commentId: string, comment: string, parentComment: string | null) {
    if (!isAuthenticated) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    if (user === null) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    // Send backend request
    const res: any = await dispatch(createReplyToPost({ postId, commentId, comment, parentId: parentComment }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("comment_success", language), "success"));
      setActiveReplyToCommentInput({
        id: commentId,
        comment: "",
        parent_comment: parentComment
      })
    } else {
      dispatch(addAlert(generateTranslatedText("comment_error", language), "warning"));
    }
  }

  async function replyToPost(postId: string, comment: string) {
    if (!isAuthenticated) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    if (user === null) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return;
    }

    // Send backend request
    const res: any = await dispatch(createReplyToPost({ postId, commentId: null, comment, parentId: null }));
    if (res.payload.status === 201) {
      dispatch(addAlert(generateTranslatedText("comment_success", language), "success"));
      setActiveReplyToPostInput({
        id: postId,
        comment: "",
      })
    } else {
      dispatch(addAlert(generateTranslatedText("comment_error", language), "warning"));
    }
  }

  async function confirmEditPost(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_edit_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const { id: postId, title, body, group, image } = editPostContent;
    if (title === "") {
      dispatch(addAlert(generateTranslatedText("missing_title_post", language), "warning"));
      return
    }

    // Get processed images from redux store
    let imageData: any = null;
    const processedImages = Object.values(images.data).filter((img) => img.success);
    if (processedImages.length === 0) {
      imageData = {
        id: image,
        source: "saved",
        data: image,
      };
    } else {
      imageData = {
        id: processedImages[0].id,
        source: "processed",
        data: processedImages[0].data,
      };
    }

    // Dispatch action
    setShowEditPostModal(false);
    const res: any = await dispatch(editPost({
      postId,
      title,
      body,
      image: imageData,
      group: group === "" ? null : group,
    }))
    if (res.payload.status === 200) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("post_updated", language), "success"));
      // window.location.reload();
    } else {
      setShowEditPostModal(true);
      dispatch(addAlert(generateTranslatedText("post_updated_error", language), "warning"));
    }
  }

  async function confirmDeletePost(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_delete_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const { id } = deletePostContent;
    if (id === "") {
      dispatch(addAlert(generateTranslatedText("missing_id_post", language), "warning"));
      return
    }

    // Dispatch action
    setShowDeletePostModal(false);
    const res: any = await dispatch(deletePost({
      postId: id
    }))
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("post_deleted", language), "success"));
    } else {
      setShowDeletePostModal(true);
      dispatch(addAlert(generateTranslatedText("post_deleted_error", language), "error"));
    }
  }

  async function confirmEditComment(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_edit_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const { id, post_id: postId, body, parent_comment: parentComment } = editCommentContent;
    if (body === "") {
      dispatch(addAlert(generateTranslatedText("missing_body_comment", language), "warning"));
      return
    }

    // Dispatch action
    setShowEditCommentModal(false);
    const res: any = await dispatch(editComment({
      postId,
      commentId: id,
      comment: body,
      parentId: parentComment
    }))
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("comment_updated", language), "success"));
    } else {
      setShowEditCommentModal(true);
      dispatch(addAlert(generateTranslatedText("comment_updated_error", language), "error"));
    }
  }

  async function confirmDeleteComment(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_delete_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const { id } = deleteCommentContent;
    if (id === "") {
      dispatch(addAlert(generateTranslatedText("missing_id_comment", language), "warning"));
      return
    }

    // Dispatch action
    setShowDeleteCommentModal(false);
    const res: any = await dispatch(deleteComment({
      commentId: id
    }))
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("comment_deleted", language), "success"));
    } else {
      setShowDeleteCommentModal(true);
      dispatch(addAlert(generateTranslatedText("comment_deleted_error", language), "error"));
    }
  }

  // Constant variables
  const language = "EN";
  const shareUrl = "https://filmwaffle.com/post/"
  const hideLongTextLimit = 300;

  return (
    <>

      {/* Share options modal */}
      <Modal
        show={showShareModal}
        setShow={setShowShareModal}
        confirmText=""
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={() => { }}
        showTriggerButton={false}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("share_to_social", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              <div className="flex flex-row items-center justify-center gap-4">

                {/* Share to Facebook */}
                <FacebookShareButton url={`${shareUrl}${post.slug}`}>
                  <div
                    data-twe-ripple-init
                    data-twe-ripple-color="light"
                    className="flex items-center inline-block rounded bg-[#1877f2] px-5 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512" style={{ width: "15px", height: "auto" }}>
                      <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                    </svg>
                  </div>
                </FacebookShareButton>

                {/* Share to Reddit */}
                <RedditShareButton url={`${shareUrl}${post.slug}`} title={generateTranslatedText("share_text_reddit", language)}>
                  <div
                    data-twe-ripple-init
                    data-twe-ripple-color="light"
                    className="flex items-center inline-block rounded bg-[#FF4500] px-4 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" style={{ width: "25px", height: "auto" }}>
                      <path d="M440.64 240.57c-5.36 0-10.39 2.01-14.32 5.32-25.09-17.53-58.67-28.81-96.32-30.8l19.41-87.16 61.33 13.6c.12 8.65 7.04 15.65 15.76 15.65 8.81 0 15.96-7.15 15.96-15.96 0-8.81-7.15-15.96-15.96-15.96-6.57 0-12.21 3.94-14.57 9.65l-67.8-15.04c-2.02-.53-4.11-.45-6.1.08-2.04.54-3.91 1.65-5.42 3.2-1.5 1.57-2.61 3.56-3.14 5.7L326.3 213.9c-39.52-1.92-75.31 6.29-101.41 21.3-5.36-5.61-12.8-9.1-21.02-9.1-17.03 0-30.88 14.58-30.88 32.57 0 8.09 2.87 15.42 7.56 20.9-2.68 1.14-5.23 2.41-7.65 3.86-25.11 14.61-41.22 39.9-41.22 67.73 0 43.92 48.73 79.54 108.81 79.54 38.09 0 72.03-12.08 92.73-31.02 6.14 2.74 13.02 4.3 20.36 4.3 7.44 0 14.44-1.57 20.66-4.39 21.29 19.18 55.71 31.1 93.71 31.1 60.08 0 108.81-35.62 108.81-79.54 0-27.83-16.11-53.12-41.22-67.73-2.48-1.45-5.04-2.72-7.71-3.86 4.61-5.41 7.47-12.74 7.47-20.73 0-17.99-13.85-32.57-30.88-32.57zM208 296.5c12.22 0 22.14 11.52 22.14 25.75s-9.92 25.75-22.14 25.75-22.14-11.52-22.14-25.75S195.78 296.5 208 296.5zm96.36 73.43c-14.51 14.14-39.53 15.48-54.33 0-1.8-1.8-1.8-4.7 0-6.5 1.8-1.8 4.7-1.8 6.5 0 10.64 10.57 29.68 10.57 40.31 0 1.8-1.8 4.7-1.8 6.5 0 1.78 1.8 1.78 4.7-.98 6.5zm54.05-22.68c-12.22 0-22.14-11.52-22.14-25.75s9.92-25.75 22.14-25.75 22.14 11.52 22.14 25.75-9.92 25.75-22.14 25.75z" />
                    </svg> */}
                    {getSocialIconWithoutProps("reddit", "", 4)}
                  </div>
                </RedditShareButton>

                {/* Copy to clipboard */}
                <button
                  onClick={() => copyUrlToClipboard(post.slug)}
                  type="button"
                  className="group rounded-md border-2 border-white hover:border-warning bg-transparent px-4 py-2.5 text-xs font-medium"
                >
                  {!copied ? (
                    <ClipboardDocumentIcon className="h-5 w-5 text-white group-hover:text-warning" />
                  ) : (
                    <ClipboardDocumentCheckIcon className="h-5 w-5 text-success" />
                  )}
                </button>

              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit post modal */}
      <Modal
        show={showEditPostModal}
        setShow={setShowEditPostModal}
        confirmText={generateTranslatedText("edit", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmEditPost}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("edit_post", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              {/* Title */}
              <TextInput
                id="update-post-title"
                type="text"
                placeholder={generateTranslatedText("title", language)}
                onChange={(e) => setEditPostContent({ ...editPostContent, title: e.target.value })}
                required={true}
                showLabel={false}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={editPostContent.title}
              />

              {/* Body */}
              {editPostContent.type === "post" && (
                <TextArea
                  id="update-post-title"
                  placeholder={generateTranslatedText("edit_post_placeholder", language)}
                  onChange={(e) => setEditPostContent({ ...editPostContent, body: e.target.value })}
                  required={true}
                  showLabel={false}
                  bgColour="black-light"
                  textColour="white"
                  borderColour="light/50"
                  placeholderColour="light/50"
                  widthClass="w-full"
                  rows={5}
                  value={editPostContent.body}
                />
              )}

              {/* Uploaded image */}
              {/* {editPostContent.image !== null && (
                <img src={editPostContent.image} alt="Post image" className="w-full h-auto rounded-lg" />
              )} */}

              {/* Image upload */}
              <UploadImageDropzone
                id="update-post-image"
                className="w-full"
                limit={1}
                savedImages={editPostContent.image === null ? [] : [{ id: `${post.id}-image`, data: editPostContent.image }]}
                bgColour="black-light"
                textColour="light/50"
                borderColour="light/50"
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete post modal */}
      <Modal
        show={showDeletePostModal}
        setShow={setShowDeletePostModal}
        confirmText={generateTranslatedText("delete", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmDeletePost}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("delete_post", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-sm font-normal text-secondary">
                {generateTranslatedText("delete_post_confirmation", language)}
              </p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit comment modal */}
      <Modal
        show={showEditCommentModal}
        setShow={setShowEditCommentModal}
        confirmText={generateTranslatedText("edit", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmEditComment}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("edit_comment", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              {/* Body */}
              <TextInput
                id="update-post-title"
                type="text"
                placeholder={generateTranslatedText("title", language)}
                onChange={(e) => setEditCommentContent({ ...editCommentContent, body: e.target.value })}
                required={true}
                showLabel={false}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={editCommentContent.body}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete comment modal */}
      <Modal
        show={showDeleteCommentModal}
        setShow={setShowDeleteCommentModal}
        confirmText={generateTranslatedText("delete", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmDeleteComment}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("delete_comment", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-sm font-normal text-secondary">
                {generateTranslatedText("delete_comment_confirmation", language)}
              </p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Content */}
      <Card className="w-full grid grid-cols-1 gap-4 mb-4 border-none" key={post.id}>

        {/* Created by */}
        <span className="flex">

          {/* Created by metadata */}
          <div className="flex items-center gap-x-2 truncate">
            <a href={`/profile/${post.created_by}`} >
              <img src={post.created_by_pic !== null ? post.created_by_pic : "/img/graphics/placeholder_profile_picture.svg"} alt={`Post created by ${post.created_by_ref}`} className="h-10 w-10 rounded-full object-cover" />
            </a>
            <div className="flex flex-col">
              <div className="flex flex-row text-secondary">
                <a href={`/profile/${post.created_by}`} >
                  <span className="text-base font-normal text-white mr-1">{post.created_by_ref}</span>
                </a>
                {post.group !== null && (
                  <span className="">
                    in<a href={`/group/${post.group[0]}`} className="ml-1 text-base font-normal text-warning hover:underline">{post.group[1]}</a>
                  </span>
                )}
              </div>
              <span className="text-xs font-normal text-secondary">{`${post.created_date}${post.updated_date !== null ? ", " + generateTranslatedText("last_updated_at", language) + post.updated_date : ""}`}</span>
            </div>
          </div>

        </span>

        {/* Content */}
        <div>

          {/* Options if creator */}
          {user !== null && post.created_by === user.profile.id && (
            <span className="flex flex-row items-center gap-x-2 mb-2">

              {/* Edit */}
              <span
                className="group flex w-fit items-center px-2 py-1 rounded-md bg-transparent border border-light/50 hover:border-warning cursor-pointer text-light/50 hover:text-warning text-sm"
                onClick={() => {
                  if (!user || !isAuthenticated) {
                    dispatch(addAlert(`${generateTranslatedText("if_you_want_to_edit_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                    return;
                  }
                  setShowEditPostModal(true)
                  setEditPostContent({
                    id: post.id,
                    group: post.group === null ? null : post.group[0],
                    title: post.title,
                    body: post.body,
                    image: post.image,
                    type: "post"
                  })
                }}
              >
                <PencilSquareIcon className="h-5 w-5 text-light/50 group-hover:text-warning mr-1" />
                Edit
              </span>

              {/* Delete */}
              <span
                className="group flex w-fit items-center px-2 py-1 rounded-md bg-transparent cursor-pointer"
                onClick={() => {
                  if (!user || !isAuthenticated) {
                    dispatch(addAlert(`${generateTranslatedText("if_you_want_to_delete_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                    return;
                  }
                  setShowDeletePostModal(true)
                  setDeletePostContent({ id: post.id })
                }}
              >
                <TrashIcon className="h-5 w-5 text-light/50 group-hover:text-danger mr-1" />
              </span>
            </span>
          )}

          {/* Small viewport */}
          <div className={`grid grid-cols-1 w-full sm:hidden`}>
            <div className={`grid grid-cols-${post.image === null ? "1" : "2 mb-4"} flex-row gap-2`}>
              {/* Title */}
              <a href={`/post/${post.slug}`} target="_blank" rel="noreferrer">
                <h3 className="text-lg font-semibold text-white hover:underline">{post.title}</h3>
              </a>

              {/* Image */}
              {post.image !== null && (
                <img src={post.image} alt={post.title} className="w-auto h-auto rounded-lg" />
              )}
            </div>

            {/* Body */}
            {hideLongText ? (
              <>
                {post.body.length <= hideLongTextLimit ? (
                  <div className="grid grid-cols-1 gap-y-3">
                    {post.body.split("\n").map((text, index) => (
                      <p key={`text-newline-${index}`} className="text-sm font-normal text-secondary">{text}</p>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm font-normal text-secondary">
                    <span>
                      <div className="grid grid-cols-1 gap-y-3">
                        {post.body.slice(0, hideLongTextLimit * showHiddenTextCounter).split("\n").map((text, index) => (
                          <p key={`text-newline-${index}`} className="text-sm font-normal text-secondary">{text}</p>
                        ))}
                      </div>
                      {hideLongTextLimit * showHiddenTextCounter < post.body.length - 1 ? "..." : null}
                    </span>
                    {hideLongTextLimit * showHiddenTextCounter > post.body.length ? null : (
                      <span
                        className="text-warning cursor-pointer ml-1 hover:underline"
                        onClick={() => setShowHiddenTextCounter(showHiddenTextCounter + 1)}
                      >
                        {generateTranslatedText("read_more", language)}
                      </span>
                    )}
                  </p>
                )}
              </>
            ) : (
              <div className="grid grid-cols-1 gap-y-3">
                {post.body.split("\n").map((text, index) => (
                  <p key={`text-newline-${index}`} className="text-sm font-normal text-secondary">{text}</p>
                ))}
              </div>
            )}
          </div>

          {/* > Small viewport */}
          <div className="hidden sm:grid sm:grid-cols-1">
            {/* Title */}
            <a href={`/post/${post.slug}`} target="_blank" rel="noreferrer">
              <h3 className="text-lg font-semibold text-white hover:underline">{post.title}</h3>
            </a>

            {/* Body */}
            {hideLongText ? (
              <>
                {post.body.length <= hideLongTextLimit ? (
                  <div className="grid grid-cols-1 gap-y-3">
                    {post.body.split("\n").map((text, index) => (
                      <p key={`text-newline-${index}`} className="text-sm font-normal text-secondary">{text}</p>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm font-normal text-secondary">
                    <span>
                      <div className="grid grid-cols-1 gap-y-3">
                        {post.body.slice(0, hideLongTextLimit * showHiddenTextCounter).split("\n").map((text, index) => (
                          <p key={`text-newline-${index}`} className="text-sm font-normal text-secondary">{text}</p>
                        ))}
                      </div>
                      {hideLongTextLimit * showHiddenTextCounter < post.body.length - 1 ? "..." : null}
                    </span>
                    {hideLongTextLimit * showHiddenTextCounter > post.body.length ? null : (
                      <span
                        className="text-warning cursor-pointer ml-1 hover:underline"
                        onClick={() => setShowHiddenTextCounter(showHiddenTextCounter + 1)}
                      >
                        {generateTranslatedText("read_more", language)}
                      </span>
                    )}
                  </p>
                )}
              </>
            ) : (
              <div className="grid grid-cols-1 gap-y-3">
                {post.body.split("\n").map((text, index) => (
                  <p key={`text-newline-${index}`} className="text-sm font-normal text-secondary">{text}</p>
                ))}
              </div>
            )}

            {/* Image */}
            {post.image !== null && (
              <img src={post.image} alt={post.title} className="w-full h-auto rounded-lg mt-4" />
            )}
          </div>
        </div>

        {/* Action buttons */}
        <div className="w-full flex justify-start">
          <div className="w-fit flex flex-row gap-4">

            {/* Upvote */}
            <Button
              bgColour="transparent"
              textColour="text-white"
              borderColour="none"
              className="items-center"
              // onClick={(e) => clickVoteButtonForPost(e, post.votes.has_upvoted ? "Downvote" : "Upvote", post.id)}
              onClick={(e) => clickVoteButtonForPost(e, "Upvote", post.id)}
            >
              <span className={`flex items-center gap-x-1 text-${post.votes.has_upvoted ? "warning" : "white"} group-hover:text-warning`}>
                {post.votes.has_upvoted ? (
                  <StarIconSolid className="h-5 w-5 text-warning shrink-0" />
                ) : (
                  <StarIconOutline className="h-5 w-5 text-white group-hover:text-warning shrink-0" />
                )}
                {post.votes.upvotes}
              </span>
            </Button>

            {/* Downvote */}
            <Button
              id={`downvote-${post.id}`}
              bgColour="transparent"
              textColour="text-white"
              borderColour="none"
              className="items-center"
              onClick={(e) => clickVoteButtonForPost(e, "Downvote", post.id)}
            >
              <span className={`flex items-center gap-x-1 text-${post.votes.has_downvoted ? "warning" : "white"} group-hover:text-warning pr-1`}>
                {post.votes.has_downvoted ? (
                  <FallingStar parentId={`downvote-${post.id}`} filled={true} colour="warning" />
                ) : (
                  <FallingStar parentId={`downvote-${post.id}`} filled={false} />
                )}
                {post.votes.downvotes}
              </span>
            </Button>

            {/* Comment */}
            <Button
              bgColour="transparent"
              textColour="text-white"
              borderColour="none"
              className="items-center"
              onClick={(e) => {
                if (chosenPostToShowComments === post.id) {
                  const commentIds = data.map((comment: CommentState) => comment.id);
                  dispatch(clearComments(commentIds));
                  setChosenPostToShowComments("");
                  setActiveReplyToPostInput({
                    id: "",
                    comment: "",
                  })
                  return;
                }
                setActiveReplyToPostInput({
                  id: post.id,
                  comment: "",
                })
                setChosenPostToShowComments(post.id)
                dispatch(getPostCommentsBySlug({ postSlug: post.slug, pageNumber: 1, highlightedComment }))
              }}
            >
              <span className="flex items-center gap-x-1 text-white group-hover:text-warning">
                <ChatBubbleOvalLeftIcon className="h-5 w-5 text-white group-hover:text-warning shrink-0" />
                {post.num_comments}
              </span>
            </Button>

            {/* Share */}
            <Button
              bgColour="transparent"
              textColour="text-white"
              borderColour="none"
              className="items-center"
              onClick={() => setShowShareModal(true)}
            >
              <ShareIcon className="h-5 w-5 text-white group-hover:text-warning shrink-0" />
            </Button>
          </div>
        </div>

        {/* Load more content */}
        {post.id !== chosenPostToShowComments ? null : loading ? (
          <div className="flex justify-center items-center w-full my-20">
            <LoadingBubbles />
          </div>
        ) : (
          <>
            {/* Reply to post input */}
            <TextInputWithIcon
              id={`reply-to-post-${post.id}`}
              name={`reply-to-post-${post.id}`}
              placeholder={generateTranslatedText("reply_placeholder", language)}
              bgColour="black"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              onChange={(e) => setActiveReplyToPostInput({ ...activeReplyToPostInput, comment: e.target.value })}
              onClick={() => replyToPost(post.id, activeReplyToPostInput.comment)}
              loading={replying}
            />

            {/* Comments */}
            <div className="grid grid-cols-1 divide-y divide-light/20">
              {data.map((comment: CommentState) => (
                <div className="grid grid-cols-1" key={`comment-${comment.id}`} id={`comment-${comment.id}`}>
                  <Card className={`w-full grid grid-cols-1 gap-4 shadow-none border-${highlightedComment === comment.id ? "1 border-warning" : "none"}`}>

                    {/* Created by */}
                    <span className="flex items-center gap-x-2">
                      <img src={comment.created_by_pic !== null ? comment.created_by_pic : "/img/graphics/placeholder_profile_picture.svg"} alt={`Comment created by ${comment.created_by_ref}`} className="h-10 w-10 rounded-full object-cover" />
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <span className="text-base font-normal text-white mr-1">{comment.created_by_ref}</span>
                        </div>
                        <span className="text-xs font-normal text-secondary">{comment.created_date}</span>
                      </div>
                    </span>

                    {/* Options if creator */}
                    {user !== null && comment.created_by === user.profile.id && (
                      <span className="flex flex-row items-center gap-x-2 mb-2">

                        {/* Edit */}
                        <span
                          className="group flex w-fit items-center px-2 py-1 rounded-md bg-transparent border border-light/50 hover:border-warning cursor-pointer text-light/50 hover:text-warning text-sm"
                          onClick={() => {
                            if (!user || !isAuthenticated) {
                              dispatch(addAlert(`${generateTranslatedText("if_you_want_to_edit_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                              return;
                            }
                            setShowEditCommentModal(true)
                            setEditCommentContent({
                              id: comment.id,
                              post_id: post.id,
                              body: comment.body,
                              parent_comment: comment.parent_comment
                            })
                          }}
                        >
                          <PencilSquareIcon className="h-5 w-5 text-light/50 group-hover:text-warning mr-1" />
                          Edit
                        </span>

                        {/* Delete */}
                        <span
                          className="group flex w-fit items-center px-2 py-1 rounded-md bg-transparent cursor-pointer"
                          onClick={() => {
                            if (!user || !isAuthenticated) {
                              dispatch(addAlert(`${generateTranslatedText("if_you_want_to_delete_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                              return;
                            }
                            setShowDeleteCommentModal(true)
                            setDeleteCommentContent({ id: comment.id })
                          }}
                        >
                          <TrashIcon className="h-5 w-5 text-light/50 group-hover:text-danger mr-1" />
                        </span>
                      </span>
                    )}

                    {/* Body */}
                    <p className="text-sm font-normal text-secondary">{comment.body}</p>

                    {/* Action buttons */}
                    <div className="w-full flex justify-start">
                      <div className="w-fit flex flex-row gap-4">

                        {/* Upvote */}
                        <Button
                          bgColour="transparent"
                          textColour="text-white"
                          borderColour="none"
                          className="items-center"
                          onClick={(e) => clickVoteButtonForComment(e, "Upvote", comment.id, comment.parent_comment)}
                        >
                          <span className={`flex items-center gap-x-1 text-${comment.votes.has_upvoted ? "warning" : "white"} group-hover:text-warning`}>
                            {comment.votes.has_upvoted ? (
                              <StarIconSolid className="h-5 w-5 text-warning" />
                            ) : (
                              <StarIconOutline className="h-5 w-5 text-white group-hover:text-warning" />
                            )}
                            {comment.votes.upvotes}
                          </span>
                        </Button>

                        {/* Downvote */}
                        <Button
                          id={`downvote-${comment.id}`}
                          bgColour="transparent"
                          textColour="text-white"
                          borderColour="none"
                          className="items-center"
                          onClick={(e) => clickVoteButtonForComment(e, "Downvote", comment.id, comment.parent_comment)}
                        >
                          <span className={`flex items-center gap-x-1 text-${comment.votes.has_downvoted ? "warning" : "white"} group-hover:text-warning`}>
                            {comment.votes.has_downvoted ? (
                              <FallingStar parentId={`downvote-${comment.id}`} filled={true} colour="warning" />
                            ) : (
                              <FallingStar parentId={`downvote-${comment.id}`} filled={false} />
                            )}
                            {comment.votes.downvotes}
                          </span>
                        </Button>

                        {/* Reply */}
                        <Button
                          bgColour="transparent"
                          textColour="text-white"
                          borderColour="none"
                          className="items-center"
                          onClick={(e) => {
                            if (!user || !isAuthenticated) {
                              dispatch(addAlert(`${generateTranslatedText("if_you_want_to_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                              return;
                            }
                            if (activeReplyToCommentInput.id === comment.id) {
                              setActiveReplyToCommentInput({
                                id: "",
                                comment: "",
                                parent_comment: null
                              })
                              return;
                            }
                            setActiveReplyToCommentInput({
                              id: comment.id,
                              comment: "",
                              parent_comment: comment.id
                            })
                          }}
                        >
                          <span className="flex items-center gap-x-1.5 text-white group-hover:text-warning">
                            <ChatBubbleLeftRightIcon className="h-5 w-5 text-white group-hover:text-warning shrink-0" />
                            {generateTranslatedText("reply", language)}
                          </span>
                        </Button>

                      </div>
                    </div>

                    {/* Reply input */}
                    {activeReplyToCommentInput.id === comment.id && (
                      <TextInputWithIcon
                        id={`reply-${comment.id}`}
                        name={`reply-${comment.id}`}
                        placeholder={generateTranslatedText("reply_placeholder", language)}
                        bgColour="black"
                        textColour="white"
                        borderColour="light/50"
                        placeholderColour="light/50"
                        widthClass="w-full"
                        onChange={(e) => setActiveReplyToCommentInput({ ...activeReplyToCommentInput, comment: e.target.value })}
                        onClick={() => replyToComment(post.id, activeReplyToCommentInput.id, activeReplyToCommentInput.comment, activeReplyToCommentInput.parent_comment)}
                        loading={replying}
                      />
                    )}

                    {/* Children comments */}
                    {comment.children.length === 0 ? null : (
                      <div className="grid grid-cols-1 divide-y divide-light/10 pl-12">
                        {comment.children.map((child: CommentState) => (
                          <div className="grid grid-cols-1" key={`child-comment-${child.id}`} id={`comment-${child.id}`}>
                            <Card className={`w-full grid grid-cols-1 gap-4 shadow-none border-${highlightedComment === child.id ? "1 border-warning" : "none"}`}>

                              {/* Created by */}
                              <span className="flex items-center gap-x-2">
                                <img src={child.created_by_pic !== null ? child.created_by_pic : "/img/graphics/placeholder_profile_picture.svg"} alt={`Comment created by ${child.created_by_ref}`} className="h-10 w-10 rounded-full object-cover" />
                                <div className="flex flex-col">
                                  <div className="flex flex-row">
                                    <span className="text-base font-normal text-white mr-1">{child.created_by_ref}</span>
                                  </div>
                                  <span className="text-xs font-normal text-secondary">{child.created_date}</span>
                                </div>
                              </span>

                              {/* Options if creator */}
                              {user !== null && child.created_by === user.profile.id && (
                                <span className="flex flex-row items-center gap-x-2 mb-2">

                                  {/* Edit */}
                                  <span
                                    className="group flex w-fit items-center px-2 py-1 rounded-md bg-transparent border border-light/50 hover:border-warning cursor-pointer text-light/50 hover:text-warning text-sm"
                                    onClick={() => {
                                      if (!user || !isAuthenticated) {
                                        dispatch(addAlert(`${generateTranslatedText("if_you_want_to_edit_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                                        return;
                                      }
                                      setShowEditCommentModal(true)
                                      setEditCommentContent({
                                        id: child.id,
                                        post_id: post.id,
                                        body: child.body,
                                        parent_comment: child.parent_comment
                                      })
                                    }}
                                  >
                                    <PencilSquareIcon className="h-5 w-5 text-light/50 group-hover:text-warning mr-1" />
                                    Edit
                                  </span>

                                  {/* Delete */}
                                  <span
                                    className="group flex w-fit items-center px-2 py-1 rounded-md bg-transparent cursor-pointer"
                                    onClick={() => {
                                      if (!user || !isAuthenticated) {
                                        dispatch(addAlert(`${generateTranslatedText("if_you_want_to_delete_comment", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                                        return;
                                      }
                                      setShowDeleteCommentModal(true)
                                      setDeleteCommentContent({ id: child.id })
                                    }}
                                  >
                                    <TrashIcon className="h-5 w-5 text-light/50 group-hover:text-danger mr-1" />
                                  </span>
                                </span>
                              )}

                              {/* Body */}
                              <p className="text-sm font-normal text-secondary">{child.body}</p>

                              {/* Action buttons */}
                              <div className="w-full flex justify-start">
                                <div className="w-fit flex flex-row gap-4">

                                  {/* Upvote */}
                                  <Button
                                    bgColour="transparent"
                                    textColour="text-white"
                                    borderColour="none"
                                    className="items-center"
                                    onClick={(e) => clickVoteButtonForComment(e, "Upvote", child.id, child.parent_comment)}
                                  >
                                    <span className={`flex items-center gap-x-1 text-${child.votes.has_upvoted ? "warning" : "white"} group-hover:text-warning`}>
                                      {child.votes.has_upvoted ? (
                                        <StarIconSolid className="h-5 w-5 text-warning" />
                                      ) : (
                                        <StarIconOutline className="h-5 w-5 text-white group-hover:text-warning" />
                                      )}
                                      {child.votes.upvotes}
                                    </span>
                                  </Button>

                                  {/* Downvote */}
                                  <Button
                                    id={`downvote-${child.id}`}
                                    bgColour="transparent"
                                    textColour="text-white"
                                    borderColour="none"
                                    className="items-center"
                                    onClick={(e) => clickVoteButtonForComment(e, "Downvote", child.id, child.parent_comment)}
                                  >
                                    <span className={`flex items-center gap-x-1 text-${child.votes.has_downvoted ? "warning" : "white"} group-hover:text-warning`}>
                                      {child.votes.has_downvoted ? (
                                        <FallingStar parentId={`downvote-${child.id}`} filled={true} colour="warning" />
                                      ) : (
                                        <FallingStar parentId={`downvote-${child.id}`} filled={false} />
                                      )}
                                      {child.votes.downvotes}
                                    </span>
                                  </Button>

                                </div>
                              </div>

                            </Card>
                          </div>
                        ))}
                      </div>
                    )}

                  </Card>
                </div>
              ))}
            </div>

            {/* Load more comments */}
            {pagination.has_next && (
              <div className="flex justify-center items-center my-2">
                {waiting ? (
                  <div className="flex justify-center items-center w-full mb-8">
                    <LoadingBubbles />
                  </div>
                ) : (
                  <Button
                    bgColour="black hover:bg-white/10"
                    textColour="warning"
                    borderColour="warning"
                    className="items-center w-fit"
                    onClick={() => {
                      dispatch(getPostCommentsBySlug({ postSlug: post.slug, pageNumber: commentPage + 1, highlightedComment }))
                      setCommentPage(commentPage + 1);
                    }}
                  >
                    <span className="flex items-center gap-x-1 text-warning">
                      {generateTranslatedText("load_more_content", language)}
                    </span>
                  </Button>
                )}
              </div>
            )}
          </>
        )}

      </Card>
    </>
  );
}
